// i18next-extract-mark-ns-start markets-offhighway

import { CAPABILITIES_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketOffHighwayPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Reducing off highway emissions and fuel consumption</h2>
						<p>
							Off highway covers all individually powered construction, agriculture and industrial equipment from excavators,
							forklifts and bulldozers through to tractors, compactors, harvesters, cranes and generators.
							In today's market with increasing emissions regulations aiming for a net zero future,
							the off highway industry is under pressure to reduce carbon emissions whilst still developing the global infrastructure
							demanded by growing economies around the world. Often operating in harsh environments with no access to fuel stations and charging points,
							manufacturers now need to balance the optimisation of existing engines with lower emissions and increased fuel economy as well as introduce new non-carbon technology.
						</p>
						<p>
							The design challenge, using simulation, is to consider the available options for each application across full electric,
							hybrid, hydrogen fuel cells, ammonia and hydrogen combustion as well as e-fuel viability.
						</p>
						<p>
							In the initial stages of development, physical prototype modelling is expensive, requiring virtual simulation tools to answer
							many of the 'What ifs'before committing to a design path.
							The propulsion system simulation is at the heart off highway design where, predicting performance across a range of applications
							will be essential to select the correct propulsion system for the correct application going forward.
						</p>
						<p>
							At Realis Simulation we have extensive experience of using software to streamline the propulsion system design process – answering many of the ‘What ifs’
							using computer simulation before Original Equipment Manufacturers (OEMs) and Tier 1 suppliers commit to expensive physical prototypes.
						</p>
						<p>
							Our software analyses the full off highway equipment system design from fuel intake, power generation, power transfer and exhaust emissions reduction.
							It simulates energy losses from friction, oil consumption and thermal heat transfer as well as component durability across various drive cycles using traditional,
							non-carbon e-fuels, hybrid, electric, hydrogen fuel cells and hydrogen combustion power units.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default MarketOffHighwayPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-offhighway", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/off-highway/Realis-Simulation_1920_offHighway.jpg" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { markets: { in: "Off highway" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 9
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
